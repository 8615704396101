import React, { useEffect, useRef, createRef } from "react"
import "./ourFocusSection2.css"
import ourFocusSection2Img1 from "../../assets/home/ourFocusSection2_img1.svg"
import ourFocusSection2Img2 from "../../assets/home/ourFocusSection2_img2.svg"
import ourFocusSection2Img3 from "../../assets/home/ourFocusSection2_img3.svg"
import createViewportObserver from "../../components/ui/animations/intersectionObserver"
import "../../components/ui/animations/glide_in.css"
import { navigate } from "gatsby"
const ourFocusSection2Mapping = [
  {
    title: "Capital Preservation",
    content:
      "The protection or preservation of capital is the foundation of any well-balanced investment portfolio. Preservation lays the groundwork for planning better returns on your other assets. C3 protects your valued investments from market volatility.",
    image: ourFocusSection2Img1,
  },
  {
    title: "Crypto Investment",
    content:
      "Cryptocurrencies volatility can be a wild ride without the proper tools. Although investing in cryptocurrency can be volatile, the basic principles of asset allocation and diversification still come into play when you build a solid crypto portfolio. A growing array of investment options make it easier to put digital tokens alongside traditional investments.",
    image: ourFocusSection2Img2,
  },
  {
    title: "Custom Portfolios",
    content:
      "There are significant benefits of building a custom portfolio to manage and invest your money to stand the test of time. You can customize your portfolio to get the most out of your investments at a comfortable risk level so that your investments match your financial goals, with less stress.",
    image: ourFocusSection2Img3,
  },
]

const OurFocusSection2 = () => {
  const cardsRef = useRef(
    Array(4)
      .fill(0)
      .map(x => createRef())
  )
  const cb = (entries, observer) => {
    // console.log(entries)
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add("bounce-box-push-screen")
      }
      // we can also append this if we want to iterate the flow again
    })
  }
  useEffect(() => {
    cardsRef.current.forEach(card => {
      card.current.classList.add("bounce-box-off-screen")
      createViewportObserver(cb, card.current)
    })
  }, [])

  return (
    <div className="ourFocusSection2_container">
      <div ref={cardsRef.current[0]} className="ourFocusSection2_header">
        <h1>Our Focus</h1>
        <button onClick={() => navigate("/contact")} className="c3_solid_btn">
          Contact Us
        </button>
      </div>
      <div className="ourFocusSection2_container_cards_container">
        {ourFocusSection2Mapping.map(({ title, image, content }, index) => (
          <div
            key={"ourFocusSection2_container_card" + index.toString()}
            className="ourFocusSection2_container_card"
            ref={cardsRef.current[index + 1]}
          >
            <img src={image} alt="IC1" />
            <h3>{title}</h3>
            <p>{content}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default OurFocusSection2
