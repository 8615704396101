import React, { useEffect, useRef, createRef } from "react"
import aiIcon from "../../assets/icons/ai_icon.png"
import "./aiSection5.css"
import "../ui/animations/glowBorder.css"
import createViewportObserver from "../../components/ui/animations/intersectionObserver"
import "../../components/ui/animations/glide_in.css"
import { navigate } from "gatsby"

const AISection5 = () => {
  const cardsRef = useRef(
    Array(1)
      .fill(0)
      .map(x => createRef())
  )
  const cb = (entries, observer) => {
    // console.log(entries)
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add("bounce-box-push-screen")
      }
      // we can also append this if we want to iterate the flow again
    })
  }
  useEffect(() => {
    cardsRef.current.forEach(card => {
      card.current.classList.add("bounce-box-off-screen")
      createViewportObserver(cb, card.current)
    })
  }, [])
  return (
    <div ref={cardsRef.current[0]} className="aiSection5_container">
      <div className="aiSection5_container_left">
        <div className="aiSection5_container_left_content">
          <h3>
            Using Ai & Machine
            <br />
            Learning to Generate
            <br /> Above Market Returns
          </h3>
          <button onClick={() => navigate("/contact")} className="c3_solid_btn">
            Get a Demo
          </button>
        </div>
      </div>
      <div className="aiSection5_container_right">
        <div className="glow_box glowing">
          <div className="glow_box_content">
            <img
              style={{ width: "100%", height: "100%" }}
              src={aiIcon}
              alt="ai_icon"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AISection5
