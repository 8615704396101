import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import BitcoinIcon from "../assets/portfolio/bitcoin.svg"
import CannabisIcon from "../assets/portfolio/cannabis.jpg"
import ChipIcon from "../assets/portfolio/chip.svg"
import VideoCarousel from "../components/homepage/VideoPlayer"
import Header from "../components/header/Header"
import Footer from "../components/footer/Footer"
import HeadingDataDiv from "../components/homepage/headingData"
import FloatingImageContainer from "../components/homepage/floatingImageContainer"
import Infoandbutton from "../components/homepage/infoandbutton"
import Performance from "../components/about/performance/Performance"
import overlayImageMobile from "../assets/overlay_mobile.jpeg"
import overlayImageDesktop from "../assets/overlay.jpeg"
import CthreeHeadingSection1 from "../components/homepage/cthreeHeadingSection1"
import {
  desktopVideosUrl,
  desktopVideosUrlApple,
  mobileVideosUrl,
  mobileVideosUrlApple,
  homePageOurFocusData,
} from "../../site_data/homepage.json"
import OurFocusSection2 from "../components/homepage/ourFocusSection2"
import OurTechnologySection3 from "../components/homepage/ourTechnologySection3"
import PerformanceSection4 from "../components/homepage/performanceSection4"
import AISection5 from "../components/homepage/aiSection5"
import FooterNew from "../components/footer/FooterNew"
import { BrowserRouter } from "react-router-dom"

const isIOS = () => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
      "Macintosh",
      "MacIntel",
      "MacPPC",
      "Mac68K",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  )
}

const floatingImageContainerData = [
  {
    image: CannabisIcon,
    borderColor: "#11BC5C",
    float: "left",
  },
  {
    image: ChipIcon,
    borderColor: "black",
    float: "right",
  },
  {
    image: BitcoinIcon,
    borderColor: "#F79923",
    float: "left",
  },
]

const ourFocusHomepageData = floatingImageContainerData.map(
  ({ image, borderColor, float }, index) => {
    return {
      image,
      borderColor,
      float,
      heading: homePageOurFocusData[index].heading,
      text: homePageOurFocusData[index].text,
    }
  }
)
export default function Home() {
  var videosUrlAndOverlay = {
    urls: desktopVideosUrl,
    overlay: overlayImageDesktop,
    type: "",
  }

  if (typeof window !== "undefined") {
    const ios = isIOS()
    // alert(ios)
    if (ios) {
      desktopVideosUrl = desktopVideosUrlApple
      mobileVideosUrl = mobileVideosUrlApple
    }
    var videosUrlChanged =
      window.innerWidth <= 575 ? mobileVideosUrl : desktopVideosUrl
    var ovelayChanged =
      window.innerWidth <= 575 ? overlayImageMobile : overlayImageDesktop
    var type = window.innerWidth <= 575 ? "-mobile" : ""
    videosUrlAndOverlay = {
      urls: videosUrlChanged,
      overlay: ovelayChanged,
      type,
    }
  }

  return (
    <div className="body-container_1">
      <Header />
      <CthreeHeadingSection1 />
      <OurFocusSection2 />
      <OurTechnologySection3 />
      <PerformanceSection4 />
      <AISection5 />
      <FooterNew />
    </div>
  )
}
