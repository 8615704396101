import React, { useEffect, useRef, createRef } from "react"
import "./ourTechnologySection3.css"
import createViewportObserver from "../../components/ui/animations/intersectionObserver"
import "../../components/ui/animations/glide_in.css"
import ourTechnologySection3Img from "../../assets/home/ourTechnologySection3_img1.svg"
import { navigate } from "gatsby"
const OurTechnologySection3 = () => {
  const cardsRef = useRef(
    Array(1)
      .fill(0)
      .map(x => createRef())
  )
  const cb = (entries, observer) => {
    // console.log(entries)
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add("bounce-box-push-screen")
      }
      // we can also append this if we want to iterate the flow again
    })
  }
  useEffect(() => {
    cardsRef.current.forEach(card => {
      card.current.classList.add("bounce-box-off-screen")
      createViewportObserver(cb, card.current)
    })
  }, [])
  return (
    <div ref={cardsRef.current[0]} className="ourTechnologySection3_conatainer">
      <div className="ourTechnologySection3_conatainer_left">
        <h1>Our Technology</h1>
        <p>
          C3 removes emotions from trading by focusing on automated trading
          using a proprietary Smart Frequency Trading algorithm which analyzes
          multiple data points to create potential trades.
          <br />
          <br />
          Smart Frequency Trading benefits our clients with long-term growth as
          well as capital preservation by utilizing risk management with
          predictive analytics to help generate above market returns. It does
          this using a combination of technical, quantitative and cognitive
          analysis.
        </p>
        <button
          onClick={() => navigate("/contact")}
          className="c3_solid_btn"
          style={{ margin: "10px 0px" }}
        >
          Contact Us
        </button>
      </div>
      <div className="ourTechnologySection3_conatainer_right">
        <img src={ourTechnologySection3Img} alt="sec3i1" />
      </div>
    </div>
  )
}

export default OurTechnologySection3
