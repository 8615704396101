import React, { useEffect, useRef, createRef } from "react"
import "./cthreeHeadingSection1.css"
import rightArrowOrange from "../../assets/icons/arrow_orange.svg"
import createViewportObserver from "../../components/ui/animations/intersectionObserver"
import "../../components/ui/animations/glide_in.css"
import { navigate } from "gatsby"
const CthreeHeadingSection1Mapping = [
  {
    image:
      "https://d2z4maap0n2bq3.cloudfront.net/" +
      "7a5957ce-bfaa-4e26-8c05-3b326021d23d.png",
    heading: "Capital Preservation",
  },
  {
    image:
      "https://d2z4maap0n2bq3.cloudfront.net/" +
      "df63edc1-0e0b-4480-b290-df9f99c4b3c0.png",
    heading: "Crypto Investments",
  },
  {
    image:
      "https://d2z4maap0n2bq3.cloudfront.net/" +
      "e868a543-61c7-4076-a928-8b7941470908.png",
    heading: "Custom Portfolios",
  },
]

const CthreeHeadingSection1 = () => {
  const cardsRef = useRef(
    Array(3)
      .fill(0)
      .map(x => createRef())
  )
  const cb = (entries, observer) => {
    // console.log(entries)
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add("bounce-box-push-screen")
      }
      // we can also append this if we want to iterate the flow again
    })
  }
  useEffect(() => {
    cardsRef.current.forEach(card => {
      card.current.classList.add("bounce-box-off-screen")
      createViewportObserver(cb, card.current)
    })
  }, [])
  return (
    <div className="cthreeHeadingSection1_container">
      <h1>Automated AI Based Trading Fund</h1>
      <p>C3 Fund, LP bases investments on 3 basic fundamentals</p>
      <div className="cthreeHeadingSection1_container_cards_container">
        {CthreeHeadingSection1Mapping.map(({ image, heading }, index) => (
          <div
            ref={cardsRef.current[index]}
            key={"cthreeHeadingSection1_container_card" + index.toString()}
            className="cthreeHeadingSection1_container_card"
          >
            <div className="cthreeHeadingSection1_container_card_img_container">
              <img src={image} alt="cthreeHeadingSection1Img1" />
            </div>
            <p>{heading}</p>
          </div>
        ))}
      </div>
      <div className="cthreeHeadingSection1_container_navigation_btns">
        <button onClick={() => navigate("/contact")} className="c3_solid_btn">
          Find out more
        </button>
        <button onClick={() => navigate("/contact")} className="c3_hollow_btn">
          Get a Demo
          <img
            style={{ paddingLeft: "5px" }}
            src={rightArrowOrange}
            alt="RAO"
          />
        </button>
      </div>
    </div>
  )
}

export default CthreeHeadingSection1
