import React, { useRef, useEffect, createRef } from "react"
import "./performanceSection4.css"
import performanceSectionImg1 from "../../assets/home/performanceSection4_img1.png"
import performanceSectionImg2 from "../../assets/home/performanceSection4_img2.png"
import performanceSectionImg3 from "../../assets/home/performanceSection4_img3.png"
import createViewportObserver from "../../components/ui/animations/intersectionObserver"
import "../../components/ui/animations/glide_in.css"
import { navigate } from "gatsby"

const performanceSection4Mapping = [
  {
    image: performanceSectionImg1,
    content:
      "The C3's AI is efficient and it rides the bull on the upside and preserves the cash when market is in downside.",
  },
  {
    image: performanceSectionImg2,
    content:
      "Out of top 25 most traded stocks, 22 stocks outsmarted the buy/hold strategy. The average gain was 35% v/s 0.38% for Buy and Hold Strategy.",
  },
  {
    image: performanceSectionImg3,
    content:
      "C3 offers custom portfolio. Example - A Set of Cannabis stocks using C3's AI outperformed the buy and hold strategy by 500%.",
  },
]

const PerformanceSection4 = () => {
  const cardsRef = useRef(
    Array(4)
      .fill(0)
      .map(x => createRef())
  )
  const cb = (entries, observer) => {
    // console.log(entries)
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add("bounce-box-push-screen")
      }
      // we can also append this if we want to iterate the flow again
    })
  }
  useEffect(() => {
    cardsRef.current.forEach(card => {
      card.current.classList.add("bounce-box-off-screen")
      createViewportObserver(cb, card.current)
    })
  }, [])
  return (
    <div ref={cardsRef.current[0]} className="performanceSection4_container">
      <h1>Performance</h1>
      <p>The goal of C3 Fund, LP is to focus on 3 industries</p>
      <div className="performanceSection4_container_cards_container">
        {performanceSection4Mapping.map(({ image, content }, index) => (
          <div
            ref={cardsRef.current[index + 1]}
            key={"performance_card" + index.toString()}
            className="performanceSection4_container_card"
          >
            <img src={image} alt="PF1" />
            <p>{content}</p>
          </div>
        ))}
      </div>
      <div className="performanceSection4_container_navigation_btns">
        <button onClick={() => navigate("/contact")} className="c3_solid_btn">
          Contact Us
        </button>
      </div>
    </div>
  )
}

export default PerformanceSection4
